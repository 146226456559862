/*
import React, { Component } from "react";
import {
  TextField,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Button,
  Typography,
  Box,
  Icon,
} from "@material-ui/core";
*/

/*import UIfx from "uifx";*/
import { UIfxLoop as UIfx } from "../Utilities/UIFX-withLoop";

import wsConnectAudio from "./ws_connect.mp3";
import wsDisconnectedAudio from "./mayday.mp3";

//import completedOrderAudio from "./completed_order/completed_order.mp3";
import completedOrderAudio from "./completed_order/chip_shuffle.mp3";

import newOrderAudio_01 from "./new_order/marimba_long.mp3";
import newOrderAudio_02 from "./new_order/service_bell.mp3";
import newOrderAudio_03 from "./new_order/vibraphone.mp3";

const wsDisconnected = new UIfx(wsDisconnectedAudio, { volume: 0.75, throttleMs: 100 });

const completeOrder = new UIfx(completedOrderAudio, {
  volume: 0.75,
  throttleMs: 100,
});
const wsConnected = new UIfx(wsConnectAudio, {
  volume: 0.75,
  throttleMs: 100,
});

let newOrderAudio = newOrderAudio_01;

let newOrder = new UIfx(newOrderAudio, {
  volume: 0.75,
  throttleMs: 100,
});
let newOrderAudioElement = null;


const playNewOrderSoundOnce = () => {
  console.log("Playing new order sound - once");
  newOrder.loop = false;
  newOrder.play();
}

const playNewOrderSoundLoop = () => {
  console.log("Playing new order sound - loop");
  if(newOrder.loop === true) {
    //already playing
  } else {
    newOrder.loop = true;
    newOrderAudioElement = newOrder.loopplay();
  }
}

const stopNewOrderSoundLoop = () => {
  console.log("Stopping new order loop");
  newOrder.loop = false;
  newOrderAudioElement.pause();
  newOrderAudioElement = null;
}

const isNewOrderSoundLoopPlaying = () => {
  return (!!newOrderAudioElement);
}

const updateNewOrderSound = (settings) => {
  if(newOrderAudioElement) {
    stopNewOrderSoundLoop();
  }
  if(!settings?.sound_new_order) {
    newOrderAudio = newOrderAudio_01;
  } else {
    switch(settings.sound_new_order) {
      case "01":
        newOrderAudio = newOrderAudio_01;
        break;
      case "02":
        newOrderAudio = newOrderAudio_02;
        break;
      case "03":
        newOrderAudio = newOrderAudio_03;
        break;
      default:
        newOrderAudio = newOrderAudio_01;
        break;
    }
  }

  newOrder = new UIfx(newOrderAudio, {
    volume: 0.75,
    throttleMs: 100,
  });

}

export {
  wsConnected, 
  wsDisconnected,
  completeOrder,
  newOrder,
  playNewOrderSoundOnce,
  playNewOrderSoundLoop,
  stopNewOrderSoundLoop,
  updateNewOrderSound,
  isNewOrderSoundLoopPlaying
};