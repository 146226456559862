/** @format */
//https://download.epson-biz.com/epson/epson_public_document.php?name=js2180.pdf

import ReceiptLineBuilder from "../Model/ReceiptLineBuilder";

export const PrintOrder = (printer, receiptLinesArray) => {
  console.log("Sending order to printer", printer, receiptLinesArray);
  console.log(JSON.stringify(receiptLinesArray));

  /*

  printer.addTextSmooth(true);

  //print title
  printer.addText(JSON.stringify(receiptLinesArray));

  //print items
  printer.addFeedLine(3);

  //print footer
  console.log("CUT_FEED", printer.CUT_FEED);
  printer.addCut(printer.CUT_FEED);

  printer.send();
  */

  function openCashDrawerEpson() {
    console.log(
      "RUNNING FUNCTION - printing-epson-ws.js - openCashDrawerEpson()"
    );
    if (printer == null) {
      console.log("Printer not connected");
    } else {
      console.log("Opening cash drawer");
      printer.addPulse(printer.DRAWER_1, printer.PULSE_100);
      printer.addPulse(printer.DRAWER_2, printer.PULSE_100);
      printer.send();
    }
    console.log("END FUNCTION - printing-epson-ws.js - openCashDrawerEpson()");
  }

  function printGenericReceiptEpson() {
    console.log(
      "RUNNING FUNCTION - printing-epson-ws.js - printGenericReceiptEpson()"
    );
    if (receiptLinesArray.length > 0) {
      if (printer === null) {
        console.log("Printer not connected");
      } else {
        if (printer.draw && printer.draw === true) {
          openCashDrawerEpson();
        }

        printer.addTextSmooth(true);

        for (var i in receiptLinesArray) {
          switch (receiptLinesArray[i].align) {
            case "LEFT":
              printer.addTextAlign(printer.ALIGN_LEFT);
              break;
            case "CENTER":
              printer.addTextAlign(printer.ALIGN_CENTER);
              break;
            case "RIGHT":
              printer.addTextAlign(printer.ALIGN_RIGHT);
              break;
            default:
              printer.addTextAlign(printer.ALIGN_LEFT);
              break;
          }

          switch (receiptLinesArray[i].font) {
            case "A":
              printer.addTextFont(printer.FONT_A);
              break;
            case "B":
              printer.addTextFont(printer.FONT_B);
              break;
            default:
              printer.addTextFont(printer.FONT_A);
              break;
          }

          switch (receiptLinesArray[i].size) {
            case 1:
              //double width, double height
              printer.addTextDouble(true, true);
              break;
            case 2:
              //double width, normal height
              printer.addTextDouble(true, false);
              break;
            case 0:
              //normal width, normal height
              printer.addTextDouble(false, false);
              break;
            default:
              printer.addTextDouble(false, false);
              break;
          }

          //inversed (true|false), underscored (true|false), bold (true|false)
          printer.addTextStyle(
            receiptLinesArray[i].inverse,
            receiptLinesArray[i].underline,
            receiptLinesArray[i].bold,
            printer.COLOR_1
          );
          printer.addText(receiptLinesArray[i].text + "\n");

          switch (receiptLinesArray[i].linefeed) {
            case false:
              //do nothing
              break;
            case true:
              printer.addFeedLine(1);
              break;
            default:
              //do nothing
              break;
          }

          switch (receiptLinesArray[i].cut) {
            case false:
              //dont cut
              break;
            case true:
              printer.addCut(printer.CUT_FEED);
              break;
            default:
              //dont cut
              break;
          }

          switch (receiptLinesArray[i].send) {
            case false:
              //dont send
              break;
            case true:
              printer.send();
              break;
            default:
              //dont send
              break;
          }
        }
      }
    } else {
      //nothing to print
    }
    console.log(
      "END FUNCTION - printing-epson-ws.js - printGenericReceiptEpson()"
    );
  }

  printGenericReceiptEpson();
};

export const buildOrderForPrinting = (order, title) => {
  let line = new ReceiptLineBuilder();

  //header
  line.setText("-".repeat(48)).nextLine();
  line.nextLine();
  line.setAlign("CENTER").setSize(1).setText(title).nextLine();
  line
    .setAlign("CENTER")
    .setSize(1) //double high, double wide
    .setText("Order ID: " + order.transaction.order_id)
    .nextLine();
  line.nextLine();
  //comments

  //items
  line.setText("-".repeat(48)).nextLine();
  for (var i in order.items) {
    line
      .setSize(1)
      .setText(order.items[i].qty + " x " + order.items[i].description)
      .nextLine();
    for (var j in order.items[i].comments) {
      line
        .setInverse(true)
        .setSize(1)
        .setText("     * " + order.items[i].comments[j])
        .nextLine();
    }
  }
  line.nextLine();

  //transaction data
  let dateoutput = new Date(
    order.transaction.date + "T" + order.transaction.time
  );

  line.setText("-".repeat(48)).nextLine();
  line
    .setSize(2)
    .setText("Date: " + dateoutput.toLocaleDateString())
    .nextLine();
  line
    .setSize(2)
    .setText("Time: " + dateoutput.toLocaleTimeString())
    .nextLine();

  if (order.transaction.balance_owing === 0) {
    line.setText("-".repeat(48)).nextLine();
    line.setAlign("CENTER").setBold(true).setSize(1).setText("PAID").nextLine();
    line.setText("-".repeat(48)).nextLine();
  } else {
    line.setText("-".repeat(48)).nextLine();
    line
      .setAlign("CENTER")
      .setBold(true)
      .setSize(1)
      .setText("BALANCE: " + order.transaction.balance_owing)
      .nextLine();
    line.setText("-".repeat(48)).nextLine();
  }
  line.nextLine();

  //customer details
  if (order.customer.name !== "") {
    line
      .setSize(1)
      .setText("Name: " + order.customer.name)
      .nextLine();
  }
  if (order.customer.comments !== "") {
    line
      .setSize(1)
      .setText("Comments: " + order.customer.comments)
      .nextLine();
  }

  //table details
  if (order.table && order.table.table_number !== 0) {
    if (order.table.desc !== "") {
      line
        .setSize(1)
        .setText(order.table.desc + ": " + order.table.table_number)
        .nextLine();
    } else {
      line
        .setSize(1)
        .setText("Table Number: " + order.table.table_number)
        .nextLine();
    }
  }

  line.nextLine();

  //line.setAlign("CENTER").setText("RAW DATA").nextLine();
  //line.setText(JSON.stringify(order, null, 2)).nextLine();

  line.setCut(true).setSend(true).finished();

  return line.getData();
};
