/** @format */

import React from "react";
import { TableCell, TableRow } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  itemComments: { color: "red" },
}));

const OrderItemComments = ({ itemcomments }) => {
  const classes = useStyles();

  if (itemcomments.length > 0) {
    return (
      <>
        {itemcomments.map((value, key) => (
          <TableRow key={value + key}>
            <TableCell
              component="th"
              scope="row"
              colSpan="2"
              align="right"
              className={classes.itemComments}
            >
              {value}
            </TableCell>
          </TableRow>
        ))}
      </>
    );
  } else {
    return null;
  }
};

export default OrderItemComments;
