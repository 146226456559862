/** @format */

import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper
} from "@material-ui/core";
import {
  Card,
  CardHeader,
  CardActions,
  CardContent,
  Button,
  IconButton,
  Box,
} from "@material-ui/core";
import OrderItemComments from "./OrderItemComments";
import { PrintDisabled, PrintRounded, EmailRounded } from "@material-ui/icons";
import EmailModal from './EmailModal.js';
import extend from "../Utilities/GeneralFunctions";
import { Order } from "../Model/Order";

const useStyles = makeStyles({
  root: {
    display: "flex",
    "flex-direction": "column",
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
    /*"border-bottom": "solid",*/
  },
  pos: {
    marginBottom: 12,
  },
  customerDetails: {
    "border-bottom": "solid",
  },
  orderItems: {
    "border-bottom": "solid",
  },
});

const OrderItem = ({ order, orderFunctions, printerEnabled, settingFunctions }) => {
  const classes = useStyles();
  const [modalIsShowing, setModal] = React.useState(false);

  const showModal = () => { setModal(true) }
  const hideModal = () => { setModal(false) }

  let settings = settingFunctions.get();

  const orderEmail = () => {
    let email = order.customer.email;
    if(email === undefined || email === "") { email = order.customer.comments?.split(":")[1]?.trim(); }
    if(email){ return email } else { return "" }
  }

  const includeEmailFunctions = () => {
    if(settings.email_enabled === "true" && settings.email_key.trim().length > 0) {
      let email = orderEmail();
      if(email !== "") {
        //console.log(order);
        return (
          <>
            <IconButton aria-label="Email Customer">
              <EmailRounded onClick={showModal} />
            </IconButton>
            <EmailModal show={modalIsShowing} handleClose={hideModal} order={order} orderFunctions={orderFunctions} email={email} settingFunctions={settingFunctions} />
          </>
        );
      } else {
        return;
      }
    } else {
      return;
    }
  }
  const determineButton = () => {
    if (order.active === true) {
      return (
        <>
          <IconButton aria-label="print order">
            {printerEnabled === true ? (
              <PrintRounded
                onClick={() =>
                  orderFunctions.handlePrintCurrentOrder(
                    order.transaction.order_id
                  )
                }
              />
            ) : (
              <PrintDisabled />
            )}
          </IconButton>
          <Button
            variant="contained"
            size="small"
            onClick={() =>
              orderFunctions.handleChangeFromCurrentToCompleted(
                order.transaction.order_id
              )
            }
          >
            Close Order
          </Button>
          {includeEmailFunctions()}
        </>
      );
    } else {
      return (
        <>
          <IconButton aria-label="print order">
            {printerEnabled === true ? (
              <PrintRounded
                onClick={() =>
                  orderFunctions.handlePrintCompletedOrder(
                    order.transaction.order_id
                  )
                }
              />
            ) : (
              <PrintDisabled />
            )}
          </IconButton>
          <Button
            variant="contained"
            size="small"
            onClick={() =>
              orderFunctions.handleChangeFromCompletedToCurrent(
                order.transaction.order_id
              )
            }
          >
            Re-Open Order
          </Button>
        </>
      );
    }
  };

  let dateoutput = new Date(
    order.transaction.date + "T" + order.transaction.time
  );

  let default_order = new Order();
  order =  extend(true, default_order, order);

  return (
    <Card className={classes.root} variant="outlined" raised="true">
      <CardHeader title={`Order #: ${order.transaction.order_id}`} />
      <CardContent>
        <Typography className={classes.orderItems} variant="h5" component="h2">
          <TableContainer component={Paper}>
            <Table
              className={classes.table}
              size="small"
              aria-label="a dense table"
            >
              <TableHead>
                <TableRow>
                  <TableCell>Qty</TableCell>
                  <TableCell>Desc</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {order.items.map((row, index) => (
                  <>
                    <TableRow key={order.transaction.order_id + "_" + index}>
                      <TableCell component="th" scope="row">
                        {row.qty}
                      </TableCell>
                      <TableCell>{row.description}</TableCell>
                    </TableRow>
                    <OrderItemComments
                      key={order.transaction.order_id + "_comment_" + index}
                      itemcomments={row.comments}
                    ></OrderItemComments>
                  </>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Typography>
        {order.customer !== null || order.table !== null ? (
          <Typography
            className={classes.customerDetails}
            variant="body2"
            component="p"
          >
            {
              //customer = {id, name, address, phone, comments}
              //console.log("Table", order.table)
            }

            {order.customer && order.customer.name !== null
              ? `Customer Name: ${order.customer.name}`
              : null}
            {order.customer && order.customer.name !== null ? <br /> : null}

            {order.table !== undefined && order.table.table_number !== 0
              ? (order.table.desc === ""
                  ? "Table Number: "
                  : order.table.desc + ": ") + order.table.table_number
              : null}
          </Typography>
        ) : null}
        <Box>&nbsp;</Box>
        <Typography variant="body2" component="p">
          {dateoutput.toLocaleDateString()}
          &nbsp;
          {dateoutput.toLocaleTimeString()}
        </Typography>
      </CardContent>
      <CardActions>{determineButton()}</CardActions>
    </Card>
  );
};

export default OrderItem;
