/**
 * @format
 */

import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
import TabCurrentOrders from "./TabCurrentOrders";
import TabCompletedOrders from "./TabCompletedOrders";
import TabSettings from "./TabSettings";

import { Toolbar } from "@material-ui/core";
import { SignalWifi4Bar } from "@material-ui/icons";
import { VolumeUp, VolumeOff, Print, PrintDisabled } from "@material-ui/icons";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    //backgroundColor: "green",
    height: "100%",
    overflowY: "scroll",
  },
  red: { color: "red" },
  lime: {
    color: "lime",
  },
  flexGrow: {
    flexGrow: 1,
  },
  icons: {
    margin: "0 1.5vw 0 0vw",
  },
}));

const AppTabs = ({
  currentOrders,
  completedOrders,
  orderFunctions,
  settingFunctions,
  disconnected,
  sound,
  printerEnabled,
}) => {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className={classes.root}>
      <AppBar position="sticky">
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="Order Type Selection"
        >
          <Tab label="Current" {...a11yProps(0)} />
          <Tab label="Completed" {...a11yProps(1)} />
          <Tab label="Settings" {...a11yProps(2)} />
          <Box className={classes.flexGrow} />
          <Toolbar>
            {sound.enabled() ? (
              <VolumeUp className={classes.icons} />
            ) : (
              <VolumeOff className={classes.icons} />
            )}
            {printerEnabled ? (
              <Print className={classes.icons} />
            ) : (
              <PrintDisabled className={classes.icons} />
            )}
            <SignalWifi4Bar
              className={[
                classes.icons,
                disconnected === false ? classes.lime : classes.red,
              ]}
            />
          </Toolbar>
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0}>
        <TabCurrentOrders
          currentOrders={currentOrders}
          orderFunctions={orderFunctions}
          printerEnabled={printerEnabled}
          settingFunctions={settingFunctions}
          activeOrders={currentOrders.length}
        />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <TabCompletedOrders
          completedOrders={completedOrders}
          orderFunctions={orderFunctions}
          printerEnabled={printerEnabled}
          settingFunctions={settingFunctions}
          activeOrders={currentOrders.length}
        />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <TabSettings settingFunctions={settingFunctions} />
      </TabPanel>
    </div>
  );
};

export default AppTabs;
