/** @format */

import React, { useEffect } from "react";
import "./App.css";
import { createTheme, CssBaseline } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/styles";

import * as Audio from './Audio/AudioFiles';

import { messageHandler } from "./Utilities/MessageHandler";
import { loadSettings, storeSettings } from "./Utilities/Settings";
import {
  recallOrderData,
  moveOrderToCompleted,
  moveOrderToCurrent,
  overwriteExistingCurrentOrder,
  addNewOrderToData,
  getOrderById,
} from "./Utilities/LocalStorageOrders";
import { buildOrderForPrinting, PrintOrder } from "./Utilities/Printing";

import AppTabs from "./Components/AppTabs.js";

//import { Print } from "@material-ui/icons";

const themeLight = createTheme({
  palette: {
    type: "light",
  },
});

const themeDark = createTheme({
  palette: {
    type: "dark",
  },
});

const App = () => {
  const [currentOrders, setCurrentOrders] = React.useState([]);
  const [completedOrders, setCompletedOrders] = React.useState([]);
  const [settings, setSettings] = React.useState(loadSettings());
  const [theme, setTheme] = React.useState(themeDark);
  const [disconnected, setDisconnected] = React.useState(true);
  const [logout] = React.useState(false);
  const [printerEnabled, setPrinterEnabled] = React.useState(false);

  const client = React.useRef(null);
  const receiptPrinter = React.useRef(null);
  const ePosReceipt = React.useRef(null);
  const printer_port = 8043; //8043 = ssl; 8008 = non-ssl

  const wssSettings = React.useRef({
    wssServer: "kitchenscreen.cloudhq4.com",
    wssPort: "8231",
    company: settings.company_name_hash,
    branch: settings.branch,
    wssAddress: "",
  });

  let pingTimeout = null;
  let printerConnectTimer = null;

  useEffect(() => {

    wssSettings.current.company = settings.company_name_hash;
    wssSettings.current.branch = settings.branch;
    wssSettings.current.wssAddress = `wss://${wssSettings.current.wssServer}:${wssSettings.current.wssPort}/${wssSettings.current.company}/${wssSettings.current.branch}`;

    client.current = new WebSocket(wssSettings.current.wssAddress);
    client.current.addEventListener("open", openEventListener);
    client.current.addEventListener("message", incomingMessageListener);
    client.current.addEventListener("ping", heartbeat);
    client.current.addEventListener("close", closeSocket);

    console.log("WebSocket component mounted");

    setTheme(settings.theme === "dark" ? themeDark : themeLight);
    Audio.updateNewOrderSound(settings);
    //settings.sound_on = true;
    updateOrderRender();
    
    if(settings.printer_ip !== "") {
      ePosReceipt.current = new window.epson.ePOSDevice();
      ePosReceipt.current.connect(
        settings.printer_ip,
        printer_port,
        cbConnectReceipt,
        { eposprint: true }
      );
    }

    return () => {
      client.current.close();
    };
  }, []);

  const openEventListener = (event) => {
    console.log("Websocket Client Connected");
    setDisconnected(false);
    playASound(Audio.wsConnected);
    client.current.send(JSON.stringify({ connection_confirmation: settings }));
  };

  const incomingMessageListener = (message) => {
    console.log(new Date() + `Received message`, message, message.data);

    let messageData = JSON.parse(message.data);

    if (messageData.message_type === "NewOrder") {
      messageHandler(messageData, addNewOrderToData);
      updateOrderRender();
      if(settings.sound_on === "yes"){
        Audio.playNewOrderSoundOnce();
      } else if(settings.sound_on === "continuous") {
        Audio.playNewOrderSoundLoop();
      }

      let title = "NEW ORDER";
      let receiptLines = buildOrderForPrinting(messageData, title);
      //loop for number of dockets to print
      for(var i = 0; i < settings.docket_copies; i++){
        PrintOrder(receiptPrinter.current, receiptLines);
      }
    } else if (messageData.message_type === "user-joined") {
      //do nothing
    } else if (messageData["message_type"] === "updater") {
      //do nothing
    } else if (messageData["message_type"] === "user-logout") {
      //do nothing
    }
  };

  const closeSocket = (event) => {
    console.log("You are disconnected");
    setDisconnected(true);
    playASound(Audio.wsDisconnected);
    setTimeout(() => {
      if (!logout) {
        console.log("Retrying connection");
        client.current = new WebSocket(wssSettings.current.wssAddress);
        client.current.addEventListener("open", openEventListener);
        client.current.addEventListener("message", incomingMessageListener);
        client.current.addEventListener("ping", heartbeat);
        client.current.addEventListener("close", closeSocket);
      }
    }, 1500);
    clearTimeout(pingTimeout);
  };

  const heartbeat = () => {
    console.log(new Date() + " Heartbeat");
    clearTimeout(this.pingTimeout);
    // Use `WebSocket#terminate()`, which immediately destroys the connection,
    // instead of `WebSocket#close()`, which waits for the close timer.
    // Delay should be equal to the interval at which your server
    // sends out pings plus a conservative assumption of the latency.
    this.pingTimeout = setTimeout(() => {
      //should only get here if there is no ping for more than 35 seconds
      console.log(new Date() + "No ping for 35 seconds");
      client.current.close();
    }, 30000 + 5000);
  };

  const settingFunctions = {
    load: function () {
      console.log("Setting functions : LOAD :");
      setSettings(loadSettings);
      return settings;
    },
    get: function () {
      return settings;
    },
    save: function (settings) {
      console.log("settingFunctions-save", settings);
      setTheme(settings.theme === "dark" ? themeDark : themeLight);
      storeSettings(settings);
      setSettings(settings);
      updateOrderRender();

      if (
        wssSettings.current.company !== settings.company_name_hash ||
        wssSettings.current.branch !== settings.branch ||
        wssSettings.current.wssAddress !==
          `wss://${wssSettings.current.wssServer}:${wssSettings.current.wssPort}/${wssSettings.current.company}/${wssSettings.current.branch}`
      ) {
        wssSettings.current.company = settings.company_name_hash;
        wssSettings.current.branch = settings.branch;
        wssSettings.current.wssAddress = `wss://${wssSettings.current.wssServer}:${wssSettings.current.wssPort}/${wssSettings.current.company}/${wssSettings.current.branch}`;
        //need to reconnect using new details
        client.current.close();
        closeSocket();
      }
    },
  };

  const updateOrderRender = () => {
    var orders = recallOrderData();
    setCurrentOrders(orders.current);
    setCompletedOrders(orders.completed);
  };

  const playASound = (whatSound) => {
    if (sound.enabled() === "yes" || sound.enabled() === "continuous") {
      whatSound.play();
    }
  };

  const orderFunctions = {
    handleChangeFromCurrentToCompleted: function (order_id) {
      playASound(Audio.completeOrder);
      let settings = settingFunctions.get();
      moveOrderToCompleted(order_id, settings.recall_limit);
      updateOrderRender();
    },
    handleChangeFromCompletedToCurrent: function (order_id) {
      moveOrderToCurrent(order_id);
      updateOrderRender();
    },
    handlePrintCurrentOrder: function (order_id) {
      let orders = recallOrderData();
      let order_to_print = getOrderById(orders.current, order_id);

      let title = "REPRINT - CURRENT";
      let receiptLines = buildOrderForPrinting(order_to_print[0], title);
      PrintOrder(receiptPrinter.current, receiptLines);
    },
    handlePrintCompletedOrder: function (order_id) {
      let orders = recallOrderData();
      let order_to_print = getOrderById(orders.completed, order_id);
      let title = "REPRINT - COMPLETED";
      let receiptLines = buildOrderForPrinting(order_to_print[0], title);
      PrintOrder(receiptPrinter.current, receiptLines);
    },
    updateExistingCurrentOrder: function (order){
      overwriteExistingCurrentOrder(order);
    },
    updateOrderRender: function () {
      var orders = recallOrderData();
      setCurrentOrders(orders.current);
      setCompletedOrders(orders.completed);
    }
  };

  const sound = {
    enabled: function () {
      let settings = settingFunctions.get();
      console.log("checking sound on value", settings.sound_on);
      return settings.sound_on;
    },
    /*
    enable: function () {
      console.log("Enable sound");
      let settings = settingFunctions.get();
      settings.sound_on = true;
      settingFunctions.save(settings);
    },
    disable: function () {
      console.log("Disable sound");
      let settings = settingFunctions.get();
      settings.sound_on = false;
      settingFunctions.save(settings);
    },
    */
  };

  function retryEpsonPrinterConnect() {
    setPrinterEnabled(false);
    clearTimeout(printerConnectTimer);
    printerConnectTimer = setTimeout(function () {
      ePosReceipt.current = new window.epson.ePOSDevice();
      ePosReceipt.current.connect(
        settings.printer_ip,
        printer_port,
        cbConnectReceipt,
        { eposprint: true }
      );
    }, 7500);
  }

  function cbConnectReceipt(data) {
    if (data === "OK" || data === "SSL_CONNECT_OK") {
      ePosReceipt.current.createDevice(
        "local_printer",
        ePosReceipt.current.DEVICE_TYPE_PRINTER,
        { crypto: true, buffer: false },
        cbCreateReceiptDevice_printer
      );
    } else {
      console.error(["Printer connection failed (1): ", data]);
      retryEpsonPrinterConnect();
    }
  }

  function cbCreateReceiptDevice_printer(data, code) {
    if (data === null) {
      console.error(["Printer connection failed (2): ", code]);
      return;
    }

    if (code === "OK") {
      receiptPrinter.current = data;
      receiptPrinter.current.startMonitor();
      setPrinterEnabled(true);

      // Set a response receipt callback function
      receiptPrinter.current.onreceive = function (res, data) {
        console.log(["Received message", res, data]);
        if (res && res.success === false) {
          console.log("PRINT FAILED!!");
        }
        if (res && res.code === "EPTR_REC_EMPTY") {
          alert(
            "Printer is out of paper - you may need to reprint the receipt after loading paper"
          );
        }
      };

      // Set a status change callback funciton
      receiptPrinter.current.onstatuschange = function (status) {
        console.log(["Printer Status Changed:", status]);
      };

      receiptPrinter.current.ononline = function () {
        console.log(["Printer Online:", "online"]);
        setPrinterEnabled(true);
      };
      receiptPrinter.current.onoffline = function () {
        console.log(["Printer Offline:", "offline"]);
        setPrinterEnabled(false);
      };
      receiptPrinter.current.onpoweroff = function () {
        console.log(["Printer Power Off:", "poweroff"]);
        setPrinterEnabled(false);
      };
      receiptPrinter.current.oncoverok = function () {
        console.log(["Printer Cover OK:", "coverok"]);
        setPrinterEnabled(true);
      };
      receiptPrinter.current.oncoveropen = function () {
        console.log(["Printer Cover Open:", "coveropen"]);
        setPrinterEnabled(false);
      };
      receiptPrinter.current.onpaperok = function () {
        console.log(["Printer Paper OK:", "paperok"]);
      };
      receiptPrinter.current.onpapernearend = function () {
        console.log(["Printer Paper Near End:", "papernearend"]);
      };
      receiptPrinter.current.onpaperend = function () {
        console.log(["Printer Paper At End:", "paperend"]);
      };
      receiptPrinter.current.ondrawerclosed = function () {
        console.log(["Printer Drawer Closed:", "drawerclosed"]);
      };
      receiptPrinter.current.ondraweropen = function () {
        console.log(["Printer Drawer Open:", "draweropen"]);
      };
      receiptPrinter.current.onbatterystatuschange = function () {
        console.log([
          "Printer Battery Status Changed:",
          "onbatterystatuschange",
        ]);
      };
      receiptPrinter.current.onbatteryok = function () {
        console.log(["Printer Battery OK:", "onbatteryok"]);
      };
      receiptPrinter.current.onbatterylow = function () {
        console.log(["Printer Battery Low:", "onbatterylow"]);
      };
    } else {
      console.log(["Other printer issue:", code]);
    }
  }

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <AppTabs
        currentOrders={currentOrders}
        completedOrders={completedOrders}
        orderFunctions={orderFunctions}
        settingFunctions={settingFunctions}
        disconnected={disconnected}
        sound={sound}
        printerEnabled={printerEnabled}
      />
    </ThemeProvider>
  );
};

export default App;
