/** @format */

import { Order } from "../Model/Order";
import extend from "./GeneralFunctions";

export const storeOrderData = (orders) => {
  localStorage.setItem("orders", JSON.stringify(orders));
};

export const recallOrderData = () => {
  var orders = {
    current: [],
    completed: [],
  };
  if (localStorage.getItem("orders")) {
    try {
      orders = JSON.parse(localStorage.getItem("orders"));
    } catch (e) {
      //probably bad settings;
      console.log("Bad orders");
    }
  } else {
    storeOrderData(orders);
  }
  //console.log("Orders loaded", orders);
  return orders;
};

export const addNewOrderToData = (new_order) => {
  new_order.active = true;
  new_order.status = "current";

  var default_order = defaultOrder();
  var adding_order =  extend(true, default_order, new_order);

  //console.log(new Date() + " Adding order", adding_order);

  var stored_orders = JSON.parse(localStorage.getItem("orders"));

  //console.log("Stored orders => ", stored_orders);

  //check if order already exists - if so, ignore
  let current_orders = stored_orders.current.filter(
    (order) => order.transaction.order_id !== adding_order.transaction.order_id
  );
  current_orders.push(adding_order);
  stored_orders.current = current_orders;

  localStorage.setItem("orders", JSON.stringify(stored_orders));

  //console.log(new Date() + " Updated current orders now contain:", stored_orders);

  return null;
};

export const moveOrderToCompleted = (order_id, limit) => {
  let orders = recallOrderData();
  let new_completed_order = orders.current.filter(
    (order) => order.transaction.order_id === order_id
  );
  let new_current_orders = orders.current.filter(
    (order) => order.transaction.order_id !== order_id
  );

  orders.current = new_current_orders;

  new_completed_order.map((order) => {
    //console.log("completedOrder", order);

    order.message_type = "CompletedOrder";
    order.status = "completed";
    order.active = false;

    let default_order = defaultOrder();
    let completed_order =  extend(true, default_order, order);
    //console.log(new Date() + " Moving completed order", completed_order);

    orders.completed.push(completed_order);
    return null;
  });

  if (orders.completed.length > limit) {
    //slice off the first item
    orders.completed = orders.completed.slice(1);
  }

  localStorage.setItem("orders", JSON.stringify(orders));
};

export const moveOrderToCurrent = (order_id) => {
  let orders = recallOrderData();

  let old_completed_order = orders.completed.filter(
    (order) => order.transaction.order_id === order_id
  );
  let new_completed_orders = orders.completed.filter(
    (order) => order.transaction.order_id !== order_id
  );

  orders.completed = new_completed_orders;

  old_completed_order.map((order) => {
    //console.log("re-openOrder", order);

    order.message_type = "ReOpenOrder";
    order.status = "current";
    order.active = true;

    let default_order = defaultOrder();
    let reopen_order =  extend(true, default_order, order);
    //console.log(new Date() + " Restoring completed order", reopen_order);

    orders.current.push(reopen_order);
    return null;
  });

  localStorage.setItem("orders", JSON.stringify(orders));
};

export const overwriteExistingCurrentOrder = (order) => {
  let orders = recallOrderData();
  /*
  let new_current_orders = orders.current.filter(
    (current_order) => current_order.transaction.order_id !== order.transaction.order_id
  );
  */
  for(var i in orders.current){
    if(orders.current[i].transaction.order_id === order.transaction.order_id){
      orders.current[i] = order;
    }
  }

  //orders.current = new_current_orders;
  //orders.current.push(order);

  localStorage.setItem("orders", JSON.stringify(orders));

}

export const getOrderById = (dataset, order_id) => {
  let order_to_return = dataset.filter(
    (order) => order.transaction.order_id === order_id
  );
  return order_to_return;
};


function defaultOrder() {
  var defaultOrder = new Order();
  return defaultOrder;
}
