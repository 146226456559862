/** @format */

import React, { Component } from "react";
import {
  TextField,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Button,
  Typography,
  Box
} from "@material-ui/core";
import VersionNumber from "../Utilities/Version";
import * as Audio from "../Audio/AudioFiles";
//import AudioFiles from "../Audio/AudioFiles";

class TabSettings extends Component {
  constructor(props) {
    super(props);

    this.state = {
      settings: this.props.settingFunctions.load(),
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSoundChange = this.handleSoundChange.bind(this);
    this.saveSettingsHandler = this.saveSettingsHandler.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
  }

  handleSoundChange(event){
    this.handleChange(event);
    
    let currentSettings = this.state.settings;
    this.props.settingFunctions.save(currentSettings);
    
    Audio.updateNewOrderSound(currentSettings);
    Audio.playNewOrderSoundOnce();
  }

  handleChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    var newSettings = this.state.settings;
    newSettings[name] = value;

    //this.props.settingFunctions.save(newSettings);

    this.setState({
      settings: newSettings,
    });
  }

  handleBlur(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    var newSettings = this.state.settings;
    newSettings[name] = value;

    this.props.settingFunctions.save(newSettings);

    this.setState({
      settings: newSettings,
    });
  }

  saveSettingsHandler(event) {
    event.preventDefault();

    var newSettings = {
      company_name: event.target.company_name.value,
      branch: event.target.branch.value,
      theme: event.target.theme.value,
      recall_limit: event.target.recall_limit.value,
      sound_on: event.target.sound_on.value,
      printer_ip: event.target.printer_ip.value,
      docket_copies: event.target.docket_copies.value,
      sound_new_order: event.target.sound_new_order.value,
      email_key:event.target.email_key.value,
      email_enabled:event.target.email_enabled.value,
      debugging:event.target.debugging.value

    };

    this.props.settingFunctions.save(newSettings);
    this.setState({ settings: newSettings });

    window.location.href = "/";
  }

  render() {
    return (
      <>
        <form onSubmit={this.saveSettingsHandler}>
          <Box>
            <Typography variant="h5">Settings</Typography>
            <br />
          </Box>
          <Box>
            <FormControl fullWidth>
              <TextField
                required
                id="txt-company-name"
                label="Company Name"
                variant="filled"
                name="company_name"
                value={this.state.settings.company_name}
                onChange={this.handleChange}
                onBlur={this.handleBlur}
              />

              <TextField
                required
                id="txt-branch"
                label="Branch Number"
                variant="filled"
                name="branch"
                value={this.state.settings.branch}
                onChange={this.handleChange}
                onBlur={this.handleBlur}
                type="number"
              />
            </FormControl>
            <FormControl fullWidth>
              <InputLabel id="theme-simple-select-label">Theme</InputLabel>
              <Select
                labelId="theme-simple-select-label"
                id="theme-select"
                value={this.state.settings.theme}
                onChange={this.handleChange}
                onBlur={this.handleBlur}
                name="theme"
                variant="filled"
              >
                <MenuItem value="dark" key="theme-dark">
                  Dark
                </MenuItem>
                <MenuItem value="light" key="theme-light">
                  Light
                </MenuItem>
              </Select>
            </FormControl>
            <FormControl fullWidth>
              <TextField
                required
                id="txt-recall-limit"
                label="Recall Limit"
                variant="filled"
                name="recall_limit"
                value={this.state.settings.recall_limit}
                onChange={this.handleChange}
                onBlur={this.handleBlur}
                type="number"
              />
              <TextField
                id="txt-printer-ip"
                label="Printer IP"
                variant="filled"
                name="printer_ip"
                value={this.state.settings.printer_ip}
                onChange={this.handleChange}
                onBlur={this.handleBlur}
              />
            </FormControl>
            <FormControl fullWidth>
              <TextField
                required
                id="txt-docket-copies"
                label="Docket Copies"
                variant="filled"
                name="docket_copies"
                value={this.state.settings.docket_copies}
                onChange={this.handleChange}
                onBlur={this.handleBlur}
              />
            </FormControl>
            <FormControl fullWidth>
              <InputLabel id="theme-simple-select-label">Sound</InputLabel>
              <Select
                labelId="theme-simple-select-label"
                id="sound-on-select"
                value={this.state.settings.sound_on}
                onChange={this.handleChange}
                onBlur={this.handleBlur}
                name="sound_on"
                variant="filled"
              >
                <MenuItem value="yes" key="sound-on-yes">
                  Yes
                </MenuItem>
                <MenuItem value="continuous" key="sound-on-yes-continuous">
                  Yes - Continuous
                </MenuItem>
                <MenuItem value="no" key="sound-on-no">
                  No
                </MenuItem>
              </Select>
            </FormControl>
            <FormControl fullWidth>
              <InputLabel id="theme-simple-select-label">New Order Sound</InputLabel>
              <Select
                labelId="theme-simple-select-label"
                id="new-order-sound-select"
                value={this.state.settings.sound_new_order}
                onChange={this.handleSoundChange}
                onBlur={this.handleBlur}
                name="sound_new_order"
                variant="filled"
              >
                <MenuItem value="01" key="sound-1">
                  Marimba (Long)
                </MenuItem>
                <MenuItem value="02" key="sound-2">
                  Service Bell
                </MenuItem>
                <MenuItem value="03" key="sound-3">
                  Vibraphone
                </MenuItem>
              </Select>
              
            </FormControl>
            
            <FormControl fullWidth>
              <InputLabel id="theme-simple-select-label">Enable Emails</InputLabel>
              <Select
                labelId="theme-simple-select-label"
                id="email-enabled"
                value={this.state.settings.email_enabled}
                onChange={this.handleChange}
                onBlur={this.handleBlur}
                name="email_enabled"
                variant="filled"
              >
                <MenuItem value="true" key="email-enabled-true">
                  Yes
                </MenuItem>
                <MenuItem value="false" key="email-enabled-false">
                  No
                </MenuItem>
              </Select>
            </FormControl>

            <FormControl fullWidth>
              <TextField
                id="txt-email-key"
                label="Email Key"
                variant="filled"
                name="email_key"
                value={this.state.settings.email_key}
                onChange={this.handleChange}
                onBlur={this.handleBlur}
                type="text"
              />
            </FormControl>

            <FormControl fullWidth>
              <InputLabel id="theme-simple-select-label">Enable Debugging</InputLabel>
              <Select
                labelId="theme-simple-select-label"
                id="debugging"
                value={this.state.settings.debugging}
                onChange={this.handleChange}
                onBlur={this.handleBlur}
                name="debugging"
                variant="filled"
              >
                <MenuItem value="true" key="debugging-enabled-true">
                  Yes
                </MenuItem>
                <MenuItem value="false" key="debugging-enabled-false">
                  No
                </MenuItem>
              </Select>
            </FormControl>
            
            
          </Box>

          <Box>&nbsp;</Box>

          <Box>
            <FormControl fullWidth>
              <Button variant="contained" type="submit">
                Save Settings
              </Button>
            </FormControl>
          </Box>
        </form>
        <Box>&nbsp;</Box>
        <Box>App Version {VersionNumber()}</Box>
      </>
    );
  }
}

export default TabSettings;
