export class Order {
  constructor() {
    this.transaction = {receipt: "",
      date: "",
      time: "",
      balance_owing: 0,
      order_id: "",
      parent_id: "",
      valid: false
    };
    this.customer = {
      id: "",
      name: "",
      address: "",
      phone: "",
      comments: "",
      email: ""
    };
    this.table = {
      desc:"",
      table_number:""
    };
    this.items = [];
    this.message_type = "";
    this.active = true;
    this.status = "";
    this.emailed = {
      can_email: false,
      prepared: "",
      ready: "",
      completed: ""
    }
  }
}