import UIfx from "uifx";

export class UIfxLoop extends UIfx {

  setLoop = loop => {
    this.loop = loop;
    return this;
  };

  pause = (audioElement, pause) => {
    if(pause === true) {
      audioElement.pause();
    }
    return this;
  };

  loopplay = volume => {
    
    this.validateVolume(volume);

    const audioElement = new Audio(this.file);
    audioElement.load();
    audioElement.addEventListener("loadeddata", () => {
      audioElement.volume = volume >= 0 && volume <= 1 ? volume : this.volume;
      audioElement.loop = this.loop;

      const audioElementPromised = audioElement.play();
      
      audioElementPromised
        .then(() => {
          // autoplay started, everyting is ok
        })
        .catch(error => {
          console.log(`UIfx says: "had a problem playing file: ${this.file}"`)
        });
    });
    
    return audioElement;
  };

}