/** @format */

import { makeStyles } from "@material-ui/core/styles";
import OrderList from "./OrderList";

const useStyles = makeStyles({
  container: {
    "margin-right": "auto",
    "margin-left": "auto",
    "padding-left": "15px",
    "padding-right": "15px",
  },
});

const TabCompletedOrders = ({
  completedOrders,
  orderFunctions,
  printerEnabled,
  settingFunctions,
  activeOrders
}) => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <OrderList
        orders={completedOrders}
        orderFunctions={orderFunctions}
        printerEnabled={printerEnabled}
        settingFunctions={settingFunctions}
        activeOrders={activeOrders}
      />
    </div>
  );
};

export default TabCompletedOrders;
