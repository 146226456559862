/** @format */

import { makeStyles } from "@material-ui/core/styles";
import { isNewOrderSoundLoopPlaying, playNewOrderSoundLoop, stopNewOrderSoundLoop } from "../Audio/AudioFiles";
import OrderItem from "./OrderItem";

const useStyles = makeStyles({
  orderlist: {
    display: "grid",
    "grid-gap": "1rem",
    "grid-template-columns": "repeat(4, minmax(300px, 1fr))",
    "grid-template-rows": "4",
  },
});

const OrderList = ({ orders, orderFunctions, printerEnabled, settingFunctions, activeOrders }) => {
  const classes = useStyles();
  if (orders && orders.length > 0) {
    //start order sound if we are set to continuous
    let current_settings = settingFunctions.get();
    if(current_settings.sound_on === "continuous" && activeOrders >= 1){
      if(!isNewOrderSoundLoopPlaying()) {
        playNewOrderSoundLoop();
      }
    }
    return (
      <div className={classes.orderlist}>
        {orders
          .slice(0)
          .reverse()
          .map((order) => (
            <OrderItem
              key={order.transaction.order_id}
              order={order}
              orderFunctions={orderFunctions}
              printerEnabled={printerEnabled}
              settingFunctions={settingFunctions}
            />
          ))}
      </div>
    );
  } else {
    //stop order sound if it is playing
    let current_settings = settingFunctions.get();
    if(current_settings.sound_on === "continuous" || current_settings.sound_on === "yes"){
      if(isNewOrderSoundLoopPlaying()) {
        stopNewOrderSoundLoop();
      }
    }
    return <div className={classes.orderlist}>No orders to show</div>;
  }
};

export default OrderList;
