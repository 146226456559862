/** @format */

const version = "1.0.5";

const VersionNumber = () => {
  return version + " - " + LastUpdate();
};

const LastUpdate = () => {
  if (document.lastModified) {
    return "Last Updated: " + document.lastModified;
  } else {
    return "";
  }
};

export default VersionNumber;
