import './css/EmailModal.css';

import { makeStyles } from "@material-ui/core/styles";
import {  Box, Button, IconButton } from "@material-ui/core";
import React from 'react';
import { CloseRounded, DoneRounded } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default
  },
  closebtn: {
    margin: "10% 0px 0px 0px",
  }
}));

const EmailModal = ({ handleClose, show, order, email, orderFunctions, settingFunctions }) => {
  const showHideClassName = show ? "modal display-block" : "modal display-none";
  const classes = useStyles();
  const [disabled_buttons, setDisabledButton] = React.useState(false);
  const [disabled_close_button, setDisabledCloseButton] = React.useState(false);
  const [last_error, setLastError] = React.useState("");

  let settings = settingFunctions.get();

  const prepareEmailSent = (order) => {
    if(order.emailed.prepared === "sent"){
      return (
        <IconButton><DoneRounded /></IconButton>
      )
    } else if (order.emailed.prepared === "failed"){
      return (
        <IconButton><CloseRounded /></IconButton>
      )
    }
  }

  const readyEmailSent = (order) => {
    if(order.emailed.ready === "sent"){
      return (
        <IconButton><DoneRounded /></IconButton>
      )
    } else if (order.emailed.ready === "failed"){
      return (
        <IconButton><CloseRounded /></IconButton>
      )
    }
  }

  const completedEmailSent = (order) => {
    if(order.emailed.completed === "sent"){
      return (
        <IconButton><DoneRounded /></IconButton>
      )
    } else if (order.emailed.completed === "failed"){
      return (
        <IconButton><CloseRounded /></IconButton>
      )
    }
  }

  const emailSentSuccess = (type, order) => {
    switch(type){
      case "prepare":
        order.emailed.prepared = "sent";
        break;
      case "ready":
        order.emailed.ready = "sent";
        break;
      case "complete":
        order.emailed.completed = "sent";
        break;
      default:
        break;
    }
    orderFunctions.updateExistingCurrentOrder(order);
    orderFunctions.updateOrderRender();
    setDisabledButton(false);
    setDisabledCloseButton(false);
  }

  const emailSentFail = (type, order) => {
    switch(type){
      case "prepare":
        order.emailed.prepared = "failed";
        break;
      case "ready":
        order.emailed.ready = "failed";
        break;
      case "complete":
        order.emailed.completed = "failed";
        break;
      default:
        break;
    }
    orderFunctions.updateExistingCurrentOrder(order);
    orderFunctions.updateOrderRender();
    setDisabledButton(false);
    setDisabledCloseButton(false);
  }

  const displayLastError = () => {
    return last_error;
  }

  const emailCustomer = (order, type, email) => {
    setDisabledButton(true);
    setDisabledCloseButton(true);
    
    let enabledEmails = settings.email_enabled;
    let debugging = settings.debugging; //process.env.REACT_APP_DEBUGGING;

    let subject = null;
    switch(type){
      case "prepare":
      case "ready":
      case "complete":
        subject = type;
        break;
      default:
        subject = "complete";
        break;
    }
    
    let email_url = process.env.REACT_APP_EMAIL_URL;
    let name = order.customer.name;
    let company_key = settings.email_key;
    let order_number = order.transaction.order_id;
    
    let email_address = "";
    if(debugging === "true"){
      email_address = "troy@cloudhq4.com";
    } else {
      email_address = email;
    }
    
    let form_json = {
      to:email_address,
      name:name,
      company_key: company_key,
      subject:subject,
      order_number:order_number,
    };
    let form_body = [];
    for (var property in form_json) {
      var encodedKey = encodeURIComponent(property);
      var encodedValue = encodeURIComponent(form_json[property]);
      form_body.push(encodedKey + "=" + encodedValue);
    }
    form_body = form_body.join("&");

    let combined_url = email_url + "?" + form_body;
    if(enabledEmails) {
      fetch(combined_url)
      .then(async response => {
          //const isJson = response.headers.get('content-type')?.includes('application/json');
          //const data = isJson && await response.json();
          let response_text = null;
          try {
            response_text = await response.text()
            const data = JSON.parse(response_text);
            console.log("Email sending results", data, response_text);

            // check for error response
            if (!response.ok) {
                // get error message from body or default to response status
                const error = (data && data.message) || response.status;
                console.log("Returning error promise", error);

                emailSentFail(type, order);
                setLastError(error);

                return Promise.reject(error);
            }
            if(data.Response === true){
              //email was sent
              emailSentSuccess(type, order);
              setLastError("");
            } else {
              emailSentFail(type, order);
              setLastError(data.Value);
            }
          } catch(e) {
            emailSentFail(type, order);
            console.error("Non json response returned", response_text, e);
            setLastError("Invalid response returned: " + response_text);
          }
      })
      .catch(error => {
          //this.setState({ errorMessage: error.toString() });
          console.error('There was an error!', error);
          emailSentFail(type, order);
          setLastError(error);
      });
    } else {
      emailSentFail(type, order);
      setLastError("Emails not enabled");
    }
    
  }

  return (
    <div className={showHideClassName}>
      <section className={"modal-main " + classes.root}>
        <center>
          <h2>Email Customer</h2>
          <Box m={1}>
            <Button disabled={disabled_buttons} variant="contained" size="medium" onClick={() => {emailCustomer(order, "prepare", email)}}>Order Prepared</Button>
            {prepareEmailSent(order)}
          </Box>
          <Box m={1}>
            <Button disabled={disabled_buttons} variant="contained" size="medium" onClick={() => {emailCustomer(order, "ready", email)}}>Order Ready</Button>
            {readyEmailSent(order)}
          </Box>
          <Box m={1}>
            <Button disabled={disabled_buttons} variant="contained" size="medium" onClick={() => {emailCustomer(order, "complete", email)}}>Order Completed</Button>
            {completedEmailSent(order)}
          </Box>
          <Box m={1}><span>{displayLastError()}</span></Box>
          <Box m={1}>
            <Button disabled={disabled_close_button} variant="contained" size="medium" onClick={handleClose} className={classes.closebtn}>Close</Button>
          </Box>
        </center>
      </section>
    </div>
  );
};

export default EmailModal;