/** @format */

import sha1 from "./Encryption";
import extend from "./GeneralFunctions";

export const storeSettings = (settings) => {
  console.log("Saving settings", settings);
  settings.company_name_hash = sha1(settings.company_name).toUpperCase();
  localStorage.setItem("program_settings", JSON.stringify(settings));
};

export const loadSettings = () => {
  var settings = null;
  if (localStorage.getItem("program_settings")) {
    try {
      var settings_default = defaultSettings();
      var settings_current = JSON.parse(localStorage.getItem("program_settings"));
      settings = extend(true, settings_default, settings_current);
      //settings = JSON.parse(localStorage.getItem("program_settings"));
    } catch (e) {
      //probably bad settings - set to default;
      settings = defaultSettings();
      storeSettings(settings);
      console.log("Bad settings - setting default settings");
    }
  } else {
    settings = defaultSettings();
    storeSettings(settings);
    console.log("Setting default settings");
  }
  console.log("Settings loaded", settings);
  return settings;
};

function defaultSettings() {
  var defaultSettings = {
    company_name: "",
    company_name_hash: "",
    branch: "001",
    theme: "dark",
    recall_limit: 100,
    sound_on: true,
    printer_ip: "",
    docket_copies: 1,
    sound_new_order:"01",
    email_key:"",
    email_enabled:true,
    debugging:false
  };
  return defaultSettings;
}
